<template>
	<v-dialog v-model="visible" max-width="500" persistent>
		<v-card>
        <h1 class="text-h5">
          Chcete túto osobu odstrániť z tímu?
        </h1>
		<v-card-text>{{ member.name + ' ' + member.surname }} bude odstránený/á z teamu.</v-card-text>
        <v-card-actions>
          <v-btn
            class="button secondary"
            variant="text"
            @click="closeModal()"
          >
            Zrušiť
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="button primary"
            variant="text"
            @click="deleteMember()"
          >
            Áno
          </v-btn>
        </v-card-actions>
      </v-card>
	</v-dialog>
</template>

<script>
import tmUtils from '@/plugins/tm@_utils/tm.utils'
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [ modalMixin ],
	data() {
		return {
			visible: false,
			member: null,
			team: null
		}
	},

	methods: {
		async showModal({ member, team }) {
			this.member  = member
			this.team 	 = team
			this.visible = true
		},

		closeModal() {
			this.visible = false
		},

		async deleteMember() {
			this.team.employees  = [...this.team.employees].filter(employee => employee.id !== this.member.id)

			try {
				if (this.team.id) {
					const { team } = await this.$axios.put_auth(`v1/teams/${this.team.id}`, this.team)
					this.$store.commit('tm/update_team', team)
				} else {
					const { team } = await this.$axios.post_auth(`v1/teams`, this.team)
					this.$store.commit('tm/save_team', team)
				}

				this.$events.emit('master-task:team-member-deleted', { teamId: this.team.id, memberId: this.member.id })
				this.closeModal()
			} catch (error) {
				this.$toast.error(this.$parseError(error))

				if (process.env.NODE_ENV === 'development')
					console.log('[z-delete-team-member-modal.vue] Error: ', error)
			}
		},

		_enrichTeam(team) {
			team.employees = tmUtils.enrichEmployees(team.employees)
			return team
		}
	}
}
</script>