<template>
	<v-row no-gutters>
		<v-col cols="5">
			<v-sheet class="py-3">
				<div class="d-flex align-center">
					<v-icon icon="mdi-clock-time-three-outline" size="large"></v-icon>
					<p class="mx-3">Odpracovaný čas</p>
				</div>
			</v-sheet>
		</v-col>

		<v-col cols="7">
			<v-sheet class="py-3">
				<p>{{ hours }} h</p>
			</v-sheet>

			<v-sheet class="py-3">
				<div class="d-flex align-center mb-3">
					<v-icon @click="showModal = true" class="add-date cursor-pointer mr-2" icon="mdi-plus" size="medium" />
					<p class="task-badge rounded-pill">Pridať záznam</p>
				</div>

				<template v-if="subtask.timesheets.length">
					<div v-for="timesheet in subtask.timesheets" :key="timesheet.id" class="d-flex align-center mb-3">
						<v-icon @click.stop="deleteTimesheet(timesheet)" class="circle-border cursor-pointer mr-2" icon="mdi-minus" size="medium" />

						<div class="d-flex align-center flex-start width-100">
							<p class="task-badge rounded-pill">{{ timesheet.time }}</p>
							<!-- <p class="ml-5">{{ timesheet.employee_name }} ({{ moment(timesheet.timestamp).format('D.M.YYYY') }})</p> -->
							<p class="ml-5">{{ timesheet.employee_name }} ({{ timesheet.timestamp }})</p>
						</div>
					</div>
				</template>
			</v-sheet>
		</v-col>
	</v-row>

	<v-dialog max-width="750" v-model="showModal" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Pridať odpracovaný čas</h1>

				<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
			</div>
			<v-card-actions class="mt-10 d-flex justify-start align-center">
				<v-text-field v-model="form.hours" variant="solo" bg-color="white" type="number" :hint="v$.form.hours.$errors ? errorUtils.parseErrors(v$.form.hours.$errors) : null" :class="{ error: v$.form.hours.$errors.length }" class="mb-2" label="Odpracovaný čas" />
			</v-card-actions>
			<v-btn @click="submit()" class="button primary mt-5">Potvrdiť</v-btn>
		</v-card>
	</v-dialog>
</template>

<script>
import moment 							from 'moment'
import errorUtils 						from '@/plugins/lib@auth/_utils/error.utils'
import useVuelidate 					from '@vuelidate/core'
import { required, numeric, minValue } 	from '@vuelidate/validators'
import tmUtils 							from '@/plugins/tm@_utils/tm.utils'
import { mapGetters } 					from 'vuex'
import modalMixin						from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [ modalMixin ],
	props: {
		hours: {
			type: Number,
			required: true
		},

		subtask: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			moment,
			v$: useVuelidate(),
			errorUtils,
			showModal: false,
			form: {
				hours: null
			}
		}
	},

	validations() {
		return {
			form: {
				hours: {required, numeric, minValue: minValue(0.1)}
			}
		}
	},

	computed: {
		...mapGetters('auth', ['user'])
	},

	methods: {
		async submit() {
			if (!await this.v$.$validate())
				return

			const subtask = JSON.parse(JSON.stringify(this.subtask))

			subtask.timesheets.push({
				id: null,
				employee_id: this.user.id,
				time: typeof this.form.hours == 'string' ? parseFloat(this.form.hours) : this.form.hours
			})

			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(this._mergeDeadlineTimeDate())

			this._update(subtask)
		},

		async deleteTimesheet({ id }) {
			const subtask = JSON.parse(JSON.stringify(this.subtask))
			subtask.timesheets = subtask.timesheets.filter(timesheet => timesheet.id != id)
			if(subtask.deadline) subtask.deadline = tmUtils.formatDateTimeForBackend(this._mergeDeadlineTimeDate())

			this._update(subtask)
		},

		closeModal() {
			this.form = {
				hours: null
			}

			this.showModal = false
			this.$nextTick(() => this.v$.$reset())
		},

		async _update(data) {
			try {
				data.timesheets = data.timesheets.map((timesheet) => {
					delete timesheet.employee_name
					return timesheet
				})
				const { subtask } = await this.$axios.put_auth(`v1/subtasks/${data.id}`, data)

				this.closeModal()
				this.$events.emit('on-subtask-edited', subtask)
			} catch (error) {
				this.$toast.error(error.message || error)

				if (process.env.NODE_ENV === 'development')
					console.log('[a-task-spent-time.vue] Error: ', error)
			}
		},

		_mergeDeadlineTimeDate() {
			const date = new Date(this.subtask.deadline)
			console.log('a-task-spent-time', this.subtask)
			const timeParts = this.subtask._deadline_time.split(':')

			date.setHours(parseInt(timeParts[0]))
			date.setMinutes(parseInt(timeParts[1]))

			return date
		},
	}
}
</script>

<style lang="sass" scoped>
.width-100
	width: 100%
</style>