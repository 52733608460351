<template>
	<v-table class="mt-7 mb-5">
		<tbody class="px-5 py-5">
			<tr v-for="subtask in enrichedtasks" :key="subtask.id">
				<td v-if="subtask?.title" class="w-25">{{ subtask.title }}</td>
				<td class="w-50" v-else>{{ subtask?.master_task }}</td>
				<td class="d-flex align-center">
					<p class="employee-selected-avatar d-flex justify-center align-center">
						{{ subtask._employeeInitials }}
					</p>
					<p class="ml-2 line-clamp">{{ subtask._employeeFullName }}</p>
				</td>
				<td>
					<p class="text-light-green" v-if="subtask.is_finished">Ukončený</p>
					<p v-if="!subtask.is_finished">Neukončený</p>
				</td>
				<td class="task-action">
					<div class="d-flex justify-start">
						<img v-if="subtask.is_finished" class="pencil-icon is-finished" :src="require('@/plugins/app/_img/icons/checkmark.svg')" alt="checkmark-icon" @click.stop="clickCompleteTask(subtask)" />
						<img v-if="!subtask.is_finished" class="pencil-icon" :src="require('@/plugins/app/_img/icons/checkmark.svg')" alt="checkmark-icon" @click.stop="clickCompleteTask(subtask)" />
						<img class="pencil-icon ml-2" :src="require('@/plugins/app/_img/icons/eye.svg')" alt="pencil-icon" @click.stop="openTaskModal(subtask)" />
						<img class="trash-icon" :src="require('@/plugins/app/_img/icons/bin.svg')" alt="trash-icon" @click.stop="openDeleteTaskModal(subtask)" />
					</div>
				</td>
			</tr>
		</tbody>
	</v-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	props: {
		subtasks: {
			type: Array,
			required: true
		}
	},

	computed: {
		...mapGetters('tm', ['enrichedEmployees']),

		enrichedtasks() {
			return this.subtasks.map((subtask) => {
				const employee = this.enrichedEmployees.find((employee) => employee.id == subtask.employee_id)

				return {
					...subtask,
					_employeeInitials: employee ? employee._initials : '?',
					_employeeFullName: employee ? employee._fullName : ''
				}
			})
		}
	},

	methods: {
		openDeleteTaskModal(subtask) {
			this.$events.emit('show-delete-task-modal', { subtask })
		},

		openTaskModal(subtask) {
			this.$events.emit('show-subtask-modal', { subtask, editMode: false })
		},

		async clickCompleteTask(subtask) {
			this.$events.emit('show-complete-task-modal', { subtask })
		}
	}
}
</script>

<style lang="sass" scoped>
v-table, tbody
	border-top: thin solid rgba(var(--v-border-color), var(--v-border-opacity))
	border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity))

td:not(:last-child)
	border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity))

.line-clamp
	display: -webkit-box
	-webkit-line-clamp: 2
	-webkit-box-orient: vertical
	overflow: hidden
	text-overflow: ellipsis
</style>
