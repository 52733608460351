<template>
	<v-dialog v-model="visible" max-width="750" max-height="90%" height="700px" persistent>
		<W-loadable :content="blocker" class="h-100">
			<v-card height="100%" class="d-flex flex-column">
				<div class="d-flex justify-space-between align-center modal-header">
					<h1>{{ blocker.id ? "Upraviť" : "Vytvoriť" }} blocker</h1>
					<v-icon @click.stop="closeModal()" icon="mdi-close" size="large"></v-icon>
				</div>

				<v-card-actions class="d-flex flex-column modal-content">
					<v-text-field @focus="onInputFocus" class="modal-input" label="Názov blockeru"
						:class="{ error: v$.blocker.name.$errors.length }"
						:hint="v$.blocker.name.$errors ? errorUtils.parseErrors(v$.blocker.name.$errors) : null"
						v-model="blocker.name" variant="solo"></v-text-field>

					<v-autocomplete :custom-filter="customFilter" :match-any="true" clearable label="Vyhľadať zamestnanca"
						:items="[...enrichedEmployees]" item-title="_fullName" item-value="id" variant="solo"
						class="modal-input" v-model="blocker.employee_id" />
					<!-- <Z-autocomplete label="Vyhľadať zamestnanca" :value="blocker?.employee ? `${blocker?.employee?.name} ${blocker?.employee?.surname}` : ''" :options="enrichedEmployees.map(employee => ({key: employee.id, value: employee._fullName}))" :clear-on-submit="false" :class="{ error: v$.blocker.employee.$errors.length }" class="modal-input" @option-selected="onEmployeeSelected" /> -->
					<Datepicker class="modal-input mb-5" v-model="datepicker" :auto-apply="true" format="dd.MM.yyyy" locale="sk"
						:class="{ error: v$.datepicker.$errors?.length }" :validations="v$.datepicker" select-text="Vybrať"
						cancel-text="Zrušiť" placeholder="Dátum" :enable-time-picker="false" ref="datepicker" />

					<v-text-field v-model="starts_at_time" type="time" variant="solo" bg-color="white"
						:hint="v$.starts_at_time.$errors ? errorUtils.parseErrors(v$.starts_at_time.$errors) : null"
						:class="{ error: v$.starts_at_time.$errors.length }" class="mb-1 modal-input" label="Začiatok" />
					<v-text-field v-model="ends_at_time" type="time" variant="solo" bg-color="white"
						:hint="v$.ends_at_time.$errors ? errorUtils.parseErrors(v$.ends_at_time.$errors) : null"
						:class="{ error: v$.ends_at_time.$errors.length }" class="modal-input" label="Koniec" />
				</v-card-actions>

				<v-btn class="button primary" @click="save()">{{ blocker.id ? "UPRAVIŤ" : "VYTVORIŤ" }}</v-btn>
			</v-card>
		</W-loadable>
	</v-dialog>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import errorUtils from "@/plugins/lib@auth/_utils/error.utils"
import VuetifyFixFocusMixin from "@/plugins/tm@modal/_mixins/vuetify-fix-focus"
import { mapGetters } from "vuex"
import moment from "moment"
import AutocompleteMixin from '@/plugins/tm@_mixins/autocomplete.mixin'
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [VuetifyFixFocusMixin, AutocompleteMixin, modalMixin],

	emits: ["close"],

	components: {
		Datepicker,
		// ZAutocomplete
	},

	data() {
		return {
			visible: false,
			v$: useVuelidate(),
			errorUtils,
			blocker: null,
			datepicker: null,
			starts_at_time: null,
			ends_at_time: null,
			employee: null
		}
	},

	computed: {
		...mapGetters("tm", ["enrichedEmployees"]),

		...mapGetters("auth", ["user"])
	},

	validations() {
		return {
			blocker: {
				name: { required },
				start: { required },
				end: { required },
				// employee: { required }
			},

			starts_at_time: { required },
			ends_at_time: { required },
			datepicker: { required }
		}
	},

	methods: {
		async showModal({ blocker }) {
			this.visible = true
			this.blocker = null
			this.datepicker = null
			this.starts_at_time = null
			this.ends_at_time = null

			this.$nextTick(() => {
				this.setFocusEvent()
				this.v$.$reset()
			})

			try {
				if (blocker?.id) {
					blocker = await this.$axios.get_auth_data(`v1/blockers/${blocker.id}`)

					this.starts_at_time = moment(blocker.start).format("HH:mm")
					this.ends_at_time = moment(blocker.end).format("HH:mm")
					this.datepicker = new Date(blocker.start)
					blocker.employee_id = blocker.employee.id
					//fixnut validacie employee_id
					this.blocker = blocker
				} else {
					this.blocker = {start: null, employee_id: this.user.id }

					if (blocker.starts_at_time && blocker.datepicker && blocker.employee_id) {
						this.datepicker = blocker.datepicker
						this.starts_at_time = blocker.starts_at_time
						this.blocker.employee_id = blocker.employee_id
					}
				}
			} catch (error) {
				this.$toast.error(this.$parseError(error))

				if (process.env.NODE_ENV === 'development')
					console.log('[z-blocker-modal.vue] Error: ', error)
			}
		},

		async save() {
			if (!(await this.v$.$validate())) return

			try {
				// this.blocker.employee_id = this.blocker.employee?.id

				if (this.blocker.id) {
					const { blocker } = await this.$axios.put_auth(`v1/blockers/${this.blocker.id}`, this.blocker)
					this.blocker = blocker
				} else {
					const { blocker } = await this.$axios.post_auth("v1/blockers", this.blocker)
					this.blocker = blocker
				}

				this.$events.emit("on-blocker-edited", this.blocker)
				this.$events.emit("blocker-created")
				this.closeModal()
			} catch (error) {
				this.$toast.error(this.$parseError(error))

				if (process.env.NODE_ENV === 'development')
					console.log('[z-blocker-modal.vue] Error: ', error)
			}
		},

		closeModal() {
			this.$refs["datepicker"].clearValue()
			this.visible = false
			this.$emit("close", this.blocker)
		},

		onEmployeeSelected({ key }) {
			this.blocker.employee = this.enrichedEmployees.find(employee => employee.id == key)
		},

		_syncDatesTimes() {
			if (!this.starts_at_time || !this.ends_at_time || !this.datepicker) return

			const splittedStart = this.starts_at_time.split(":")
			const splittedEnd = this.ends_at_time.split(":")

			this.blocker.start = moment(this.datepicker)
				.set({ hours: parseInt(splittedStart[0]), minutes: parseInt(splittedStart[1]) })
				.format("YYYY-MM-DD HH:mm")
			this.blocker.end = moment(this.datepicker)
				.set({ hours: parseInt(splittedEnd[0]), minutes: parseInt(splittedEnd[1]) })
				.format("YYYY-MM-DD HH:mm")
		}
	},

	watch: {
		ends_at_time() {
			this._syncDatesTimes()
		},

		starts_at_time() {
			this._syncDatesTimes()
		},

		datepicker() {
			this._syncDatesTimes()
		}
	}
}
</script>
