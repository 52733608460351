<template>
	<v-dialog v-model="visible" max-width="750" persistent>
		<v-card>
			<div class="d-flex justify-space-between align-center">
				<h1>Ste si istý, že chcete zmazať <b>{{ mastertask.name }}</b>?</h1>

				<v-icon
					@click.stop="closeModal()"
					icon="mdi-close"
					size="large"
					class="mb-15"
				></v-icon>
			</div>
			<v-card-actions class="mt-10 d-flex justify-start align-center mobile-view">
				<v-btn class="button primary half" variant="flat" @click="deleteMasterTask()" :disabled="isDeleting">ÁNO</v-btn>
				<v-btn class="button primary half outline" @click.stop="closeModal()">ZRUŠIŤ</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import modalMixin from '@/plugins/tm@modal/_mixins/modal.mixin'

export default {
	mixins: [ modalMixin ],
	emits: ['on-mastertask-deleted'],

	data() {
		return {
			visible: false,
			mastertask: null,
			isDeleting: false
		}
	},

	methods: {
		async showModal({ mastertask }) {
			this.mastertask = mastertask
			this.visible = true
		},

		closeModal() {
			this.visible = false
		},

		async deleteMasterTask() {
			if (this.isDeleting) return

			this.isDeleting = true

			try {
				await this.$axios.delete_auth(`v1/mastertasks/${this.mastertask.id}`)
				this.$emit('on-mastertask-deleted', this.mastertask)
				this.closeModal()
			} catch (error) {
				this.$toast.error(this.$parseError(error))

				if (process.env.NODE_ENV === 'development')
					console.log('[z-delete-master-task-modal.vue] Error: ', error)
			} finally {
				this.isDeleting = false
			}
		}
	}
}
</script>